/**
 * Detect the current active responsive breakpoint in Bootstrap
 * @returns {string}
 */

function getResponsiveBreakpoint() {
  var envs = {
    xs: "d-none",
    sm: "d-sm-none",
    md: "d-md-none",
    lg: "d-lg-none",
    xl: "d-xl-none",
  };
  var env = "";

  var $el = $("<div>");
  $el.appendTo($("body"));

  for (var i = Object.keys(envs).length - 1; i >= 0; i--) {
    env = Object.keys(envs)[i];
    $el.addClass(envs[env]);
    if ($el.is(":hidden")) {
      break; // env detected
    }
  }
  $el.remove();
  return env;
}

/**
 * Adds sidebar to current menu item on smaller screen Tablets and Mobile
 * @returns {void}
 */
function setSubMenu() {
  if ($(".navbar-main-links .nav-item.active").length <= 0) {
    // console.log("add sub");
    return false;
  }

  var target = "nav.navbar-main .navbar-main-links > .active",
    sidebar_nav = $("#navbarAsideContent .navbar-aside").parent().html(),
    dropdown = $("<div>", {
      class: "dropdown-menu show",
      "aria-labelledby": "navbarDropdown",
      html: sidebar_nav,
    });

  if ($.inArray(getResponsiveBreakpoint(), ["md", "sm", "xs"]) !== -1) {
    if (!$(target).hasClass("mobified")) {
      dropdown.appendTo(target);
      $(target).addClass("dropdown show mobified");
      $(target + " > a")
        .addClass("dropdown-toggle")
        .attr("data-toggle", "dropdown");
    }
  } else {
    $(target + ".nav-item .dropdown-menu").remove();
    $(target).removeClass("dropdown show mobified");
    $(target + " a")
      .removeClass("dropdown-toggle")
      .attr("data-toggle", "");
  }
}

function updateChartTicks(scale) {
  var incrementAmount = 0;
  var previousAmount = 0;
  var newTicks = [];
  newTicks = scale.ticks;
  for (x = 0; x < newTicks.length; x++) {
    incrementAmount = previousAmount - newTicks[x];
    previousAmount = newTicks[x];
  }
  if (newTicks.length > 2) {
    if (newTicks[0] - newTicks[1] !== incrementAmount) {
      newTicks[0] = newTicks[1] + incrementAmount;
    }
  }
  return newTicks;
}

// move to another input box after maxLength is hit on verification
$(".tel_num").keyup(function () {
  if (this.value.length === this.maxLength) {
    $(this).nextAll(".tel_num").eq(0).focus();
  }

  if (this.value.length === 0) {
    $(this).prevAll(".tel_num").eq(0).focus();
  }
});

// Power Edit Snippet
$(".editable-input input").keyup(function () {
  $(this).val(
    $(this)
      .val()
      .replace(/[^0-9\.]/g, "")
  );
  if (
    (event.which !== 46 || $(this).val().indexOf(".") !== -1) &&
    (event.which < 48 || event.which > 57)
  ) {
    event.preventDefault();
  }
});

$('[data-toggle="tooltip"]').tooltip();

// $(".toggle-link").click(function(e) {
//   if ($(".toggle-link").hasClass("collapsed")) {
//     $(".toggle-link").text("Show less");
//     console.log("asa");
//   } else {
//     $(".toggle-link").text("Show less");
//   }
// });

$(".toggle-link").click(function () {
  $(this).text(function (i, old) {
    return old === "See more" ? "See less" : "See more";
  });
});

$("body").on("click", ".navbar-profile-dropdown .dropdown-menu", function (e) {
  $(this).parent().is(".show") && e.stopPropagation();
});

$("body").on(
  "click",
  ".navbar-profile-dropdown .dropdown-menu #navbarProfileDropdown22",
  function (e) {
    $(this).next().toggleClass("show");
  }
);

// for multiselect category button
//  $('.multiselect').multiselect({
//   buttonClass: 'form-control btn w-100 text-left bg-white'
// });

// $('.dropdown-menu').click(function(e) {
//   // e.stopPropagation();
//   if ($(e.target).is('[data-toggle=modal]')) {
//     $($(e.target).data('target')).modal();
//   }
// });

//mall item details page quantity input styling
jQuery(
  '<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>'
).insertAfter(".quantity input");
jQuery(".quantity").each(function () {
  var spinner = jQuery(this),
    input = spinner.find('input[type="number"]'),
    btnUp = spinner.find(".quantity-up"),
    btnDown = spinner.find(".quantity-down"),
    min = input.attr("min"),
    max = input.attr("max");
  btnUp.click(function () {
    var oldValue = parseFloat(input.val());
    if (oldValue >= max) {
      var newVal = oldValue;
    } else {
      var newVal = oldValue + 1;
    }
    spinner.find("input").val(newVal);
    spinner.find("input").trigger("change");
    $(this).parent().parent().siblings(".updateBtn").removeClass("d-none");
  });
  btnDown.click(function () {
    var oldValue = parseFloat(input.val());
    if (oldValue <= min) {
      var newVal = oldValue;
    } else {
      var newVal = oldValue - 1;
    }
    spinner.find("input").val(newVal);
    spinner.find("input").trigger("change");
    $(this).parent().parent().siblings(".updateBtn").removeClass("d-none");
  });
});

(function () {
  // hold onto the drop down menu
  var dropdownMenu;

  // and when you show it, move it to the body
  $(".table-responsive .table").on("show.bs.dropdown", function (e) {
    // grab the menu
    console.log("show");
    dropdownMenu = $(e.target).find(".dropdown-menu");

    // detach it and append it to the body
    $("body").append(dropdownMenu.detach());

    // grab the new offset position
    var eOffset = $(e.target).offset();

    // make sure to place it where it would normally go (this could be improved)
    dropdownMenu.css({
      display: "block",
      top: eOffset.top + $(e.target).outerHeight(),
      left: eOffset.left,
    });
  });

  // and when you hide it, reattach the drop down, and hide it normally
  $(".table-responsive .table").on("hide.bs.dropdown", function (e) {
    $(e.target).append(dropdownMenu.detach());
    dropdownMenu.hide();
  });
})();
